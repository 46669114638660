.ifs-advantages {
  &__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4rem;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 26%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    @media (max-width: em(900)) {
      flex-basis: 34%;

      @media (max-width: em(590)) {
        flex-basis: 100%;
      }
    }
  }

  img {
    width: 10rem;
    height: 10rem;
  }

  .btn-primary {
    margin-top: 4rem;
    margin-inline: auto;
  }
}
