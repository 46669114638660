.iso22000-target {
  .h2 {
    margin-top: 4rem;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    gap: fluid(6rem, 3rem);

    @media (max-width: em(650)) {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  img {
    width: 10rem;
    height: 10rem;
    background-color: #8a7c7c;
  }

  .block-border-green {
    margin-top: 3rem;
  }
}
