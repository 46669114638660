.feedback {
  &__swiper {
    padding-inline: 12.5rem;
    max-width: 115rem;

    @media (max-width: em(660)) {
      padding-inline: 2rem;

      @media (max-width: em(500)) {
        padding-inline: 0;
      }
    }
  }

  .swiper-slide {
    border-radius: 2.4rem;
    padding: 5.2rem;
    min-height: 4rem;
    background: var(--clr-secondary-200);

    @media (max-width: em(600)) {
      padding: 3rem;
    }
  }

  &__caption {
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 119%;
    letter-spacing: -0.01em;
    color: var(--clr-default-100);

    @media (max-width: em(600)) {
      font-size: 2.5rem;
    }
  }

  &__text {
    margin-top: 2.4rem;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 145%;
    color: rgba(255, 255, 255, 0.64);

    @media (max-width: em(400)) {
      font-size: 1.3rem;
    }

    a {
      text-decoration: underline;
      color: var(--clr-default-100);
      text-decoration-skip-ink: none;
    }

    span {
      color: var(--clr-default-100);
    }

    p {
      margin-bottom: 1rem;
    }
  }

  &__happy {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 144%;
    color: rgba(255, 255, 255, 0.8);
  }

  &__next,
  &__prev {
    position: absolute;
    top: 50%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid rgba(25, 152, 165, 0.1);
    border-radius: 1.2rem;
    width: 7rem;
    height: 7rem;
    background-color: rgba($color: #4caf4f, $alpha: 0.05);
    transform: translateY(-50%);
    cursor: pointer;

    @media (max-width: em(660)) {
      top: 80%;
    }
  }

  &__prev {
    left: 0;
  }

  &__next {
    right: 0;
  }
}
