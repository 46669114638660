.support-audits-different {
  .h2 {
    margin-top: 2rem;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }

  &__item {
    display: flex;
    gap: 3rem;

    @media (max-width: em(750)) {
      flex-direction: column-reverse;
    }
  }

  &__info {
    display: flex;
    flex-grow: 1;
    flex-basis: 60%;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__caption {
    font-size: fluid(2.5rem, 1.6rem);
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    span {
      font-weight: 700;
    }
  }

  &__img {
    position: relative;
    flex-grow: 1;
    width: 40rem;
    aspect-ratio: 16/9;

    @media (max-width: em(750)) {
      margin-inline: auto;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
