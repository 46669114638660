.iso22000-optimize {
  &__block {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
    gap: 3rem;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 26%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  img {
    width: 10rem;
    height: 10rem;
    background-color: #7e7e7e;
  }
}
