.subscribe-service-result {
  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 34%;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @media (max-width: em(650)) {
      flex-basis: 100%;
    }
  }

  img {
    height: 10rem;
  }

  &__caption {
    font-weight: 700;
  }

  &__text {
    max-width: 50rem;
  }
}
