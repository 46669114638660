.subscribe-service-who {
  &__block {
    display: flex;
    margin-top: 3rem;
    gap: 3rem;

    @media (max-width: em(840 )) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 26%;
    flex-direction: column;
    align-items: center;
    border-radius: 0.8rem;
    padding: fluid(2rem, 1rem);
    box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);
    gap: 1rem;

    @media (max-width: em(840 )) {
      max-width: 40rem;
    }
  }

  img {
    height: 10rem;
  }

  &__caption {
    font-weight: 700;
  }

  .block-border-green {
    margin-top: 2rem;
  }
}
