.management-system-advantages {
  &__title {
    margin-top: 2rem;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 26%;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;

    @media (max-width: em(1075)) {
      flex-basis: 34%;

      @media (max-width: em(600)) {
        flex-basis: 100%;
      }
    }
  }

  &__caption {
    min-height: 3.6rem;
    font-size: 1.6rem;
  }

  &__text {
    display: flex;
    flex-grow: 1;
  }
}
