.management-system-how {
  &__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 4rem;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 26%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    @media (max-width: em(1000)) {
      flex-basis: 34%;

      @media (max-width: em(720)) {
        flex-basis: 100%;
      }
    }

    img {
      height: 10rem;
    }
  }
}
