.fssc22000-steps {
  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 21%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    @media (max-width: em(995)) {
      flex-basis: 26%;

      @media (max-width: em(820)) {
        flex-basis: 34%;

        @media (max-width: em(500)) {
          flex-basis: 100%;
        }
      }
    }
  }

  img {
    width: 10rem;
    height: 10rem;
  }
}
