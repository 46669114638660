.input-text {
  position: relative;
  width: 19rem;

  input {
    display: block;
    border: 0;
    border-bottom: 0.2rem var(--clr-default-900) solid;
    padding: 1.5rem 0;
    width: 100%;
    font-size: 1.8rem;
    color: var(--clr-default-900);
    background-color: transparent;
  }

  input:focus,
  input:valid {
    outline: 0;
    border-bottom-color: var(--clr-default-900);
  }

  label {
    position: absolute;
    left: 0;
    top: 1.5rem;
    pointer-events: none;
  }

  label span {
    display: inline-block;
    min-width: 0.5rem;
    font-size: 1.8rem;
    color: var(--clr-default-900);
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  input:focus + label span,
  input:valid + label span {
    color: var(--clr-default-900);
    transform: translateY(-3rem);
  }
}
