.btn-primary {
  display: flex;
  border: 0.1rem solid var(--clr-primary-100);
  border-radius: 0.8rem;
  padding: 1.5rem 4.5rem;
  font-weight: 500;
  font-size: 1.7rem;
  color: #181818;
  color: var(--clr-default-100);
  background: var(--clr-primary-100);
  filter: drop-shadow(0.2rem 0.2rem 0.3rem rgba(0, 0, 0, 0.2));
  cursor: pointer;
}
