/* stylelint-disable */

@mixin bg-cover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@mixin flex {
  display: flex;
}

@mixin flex-v-center {
  display: flex;
  align-items: center;
}

@mixin flex-h-center {
  display: flex;
  justify-content: center;
}

@mixin flex-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-all-sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin image-set($pathToImage) {
  background-image: url("#{$pathToImage}.jpg");
  background-image: image-set(
    "#{$pathToImage}.avif"type("image/avif"),
    "#{$pathToImage}.webp"type("image/webp"),
    "#{$pathToImage}.jpg"type("image/jpg")
  );
}
