.management-system-choice {
  &__block {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    gap: 3rem;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    gap: 2rem;

    @media (max-width: em(670)) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &__info {
    flex-basis: 60%;
  }

  &__caption {
    @media (max-width: em(670)) {
      text-align: center;
    }
  }

  &__text {
    margin-top: 1rem;
  }

  &__img {
    img {
      width: 40rem;

      @media (max-width: em(670)) {
        width: 100%;
      }
    }
  }
}
