.big-form {
  padding-bottom: fluid(10rem, 4rem);

  &__block {
    display: flex;
    justify-content: center;
    margin-inline: auto;
    gap: fluid(4rem, 1.3rem);

    @media (max-width: em(600)) {
      flex-direction: column;
      align-items: center;

      .container-btn {
        margin-top: 2rem;
      }
    }
  }
}
