.gmp-why {
  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 26%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    @media (max-width: em(840)) {
      flex-basis: 34%;

      @media (max-width: em(570)) {
        flex-basis: 100%;
      }
    }
  }

  img {
    width: 10rem;
    height: 10rem;
    background-color: #8d8d8d;
  }
}
