// <МИКСИНЫ>==================================================================
@import "./settings/mixins";

// </МИКСИНЫ>=================================================================

// ФУНКЦИИ====================================================================
@import "./settings/functions";

// </ФУНКЦИИ>=================================================================

// <Подключаем шрифты>========================================================
@import "./settings/fonts";

// </Подключаем шрифты>=======================================================

// <ОБНУЛЕНИЕ>================================================================
@import "./settings/normalize";

// </ОБНУЛЕНИЕ>===============================================================

// <ПЕРЕМЕННЫЕ>===============================================================
$containerWidth: 130rem;

:root {
  // base
  --font-weight: 400;
  --content-width: 127rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;

  //fonts
  --font-family-main-100: "Manrope";
  --font-family-title-100: "EB Garamond";

  // colors
  --clr-default-100: #fff;
  --clr-default-400: #181818;
  --clr-default-900: #000;

  --clr-primary-100: #4caf4f;

  --clr-secondary-100: #6a0572;
  --clr-secondary-200: #5d1e84;

  --clr-dark-100: #292929;
}

// </ПЕРЕМЕННЫЕ>==============================================================

// <ОБОЛОЧКИ>================================================================
body {
  font-size: 1.6rem;
  color: var(--clr-default-400);

  * {
    text-align: justify;
  }

  &.lock {
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    overscroll-behavior: none;
  }

  /* scroll bar */
  &::-webkit-scrollbar {
    width: 0.7rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--clr-primary-100);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--clr-default-100);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background-color: rgb(0 0 0 / 50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition), visibility var(--transition);
  }

  &[data-overlay="true"]::before {
    opacity: 1;
    visibility: visible;
    transition: opacity var(--transition), visibility var(--transition);
  }
}

main {
  flex-grow: 1;
}

// </ОБОЛОЧКИ>===============================================================

// <СЕТКА>===================================================================
.container {
  margin-inline: auto;
  padding-inline: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

// Components - UI
@import "./ui";

// Components - UI

// Components - UI
@import "./components/ui/menu";
@import "./components/ui/logo";
@import "./components/ui/burger";
@import "./components/ui/input-text";
@import "./components/ui/text";
@import "./components/ui/btn-primary";

// Components - UI

// Components - Common
@import "./components/common/header";
@import "./components/common/footer";
@import "./components/common/faq";
@import "./components/common/big-form";
@import "./components/common/order";
@import "./components/common/team";
@import "./components/common/feedback";
@import "./components/common/photo-gallery";

// Components - Common

// Components - Pages
@import "./components/pages/index/hero";
@import "./components/pages/index/services";
@import "./components/pages/index/contacts";

@import "./components/pages/corporate-training/corporate-training-hero";
@import "./components/pages/corporate-training/corporate-training-if";
@import "./components/pages/corporate-training/corporate-training-adaptive";
@import "./components/pages/corporate-training/corporate-training-companies";
@import "./components/pages/corporate-training/corporate-training-features";

@import "./components/pages/management-system/management-system-hero";
@import "./components/pages/management-system/management-system-advantages";
@import "./components/pages/management-system/management-system-list";
@import "./components/pages/management-system/management-system-how";
@import "./components/pages/management-system/management-system-choice";

@import "./components/pages/support-audits/support-audits-hero";
@import "./components/pages/support-audits/support-audits-different";
@import "./components/pages/support-audits/support-audits-mandatory";
@import "./components/pages/support-audits/support-audits-details";

@import "./components/pages/subscribe-service/subscribe-service-hero";
@import "./components/pages/subscribe-service/subscribe-service-include";
@import "./components/pages/subscribe-service/subscribe-service-who";
@import "./components/pages/subscribe-service/subscribe-service-result";
@import "./components/pages/subscribe-service/subscribe-service-how";

@import "./components/pages/iso22000/iso22000-target";
@import "./components/pages/iso22000/iso22000-why";
@import "./components/pages/iso22000/iso22000-companies";
@import "./components/pages/iso22000/iso22000-steps";
@import "./components/pages/iso22000/iso22000-optimize";

@import "./components/pages/fssc22000/fssc22000-why";
@import "./components/pages/fssc22000/fssc22000-who";
@import "./components/pages/fssc22000/fssc22000-steps";
@import "./components/pages/fssc22000/fssc22000-what";
@import "./components/pages/fssc22000/fssc22000-implement";

@import "./components/pages/gmp/gmp-advantages";
@import "./components/pages/gmp/gmp-why";
@import "./components/pages/gmp/gmp-companies";
@import "./components/pages/gmp/gmp-how";

@import "./components/pages/brc/brc-substandards";
@import "./components/pages/brc/brc-advantages";
@import "./components/pages/brc/brc-steps";
@import "./components/pages/brc/brc-why";

@import "./components/pages/ifs/ifs-new";
@import "./components/pages/ifs/ifs-steps";
@import "./components/pages/ifs/ifs-advantages";
@import "./components/pages/ifs/ifs-who";
@import "./components/pages/ifs/ifs-info";

@import "./components/pages/iso9001/iso9001-advantages";
@import "./components/pages/iso9001/iso9001-who";
@import "./components/pages/iso9001/iso9001-steps";
@import "./components/pages/iso9001/iso9001-features";
@import "./components/pages/iso9001/iso9001-why";

// Components - Pages

// </СЕТКА>==================================================================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================
