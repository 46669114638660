.fssc22000-who {
  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 26%;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @media (max-width: em(600)) {
      flex-basis: 34%;

      @media (max-width: em(410)) {
        flex-basis: 100%;
      }
    }
  }

  img {
    width: 10rem;
    height: 10rem;
  }

  .block-border-green {
    margin-top: 4rem;
  }

  .btn-primary {
    margin-top: 2rem;
    margin-inline: auto;
  }
}
