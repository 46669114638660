.support-audits-mandatory {
  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 34%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    @media (max-width: em(630)) {
      flex-basis: 100%;
    }
  }

  &__img {
    img {
      height: 10rem;
    }
  }

  &__caption {
    text-align: center;
  }
}
