.corporate-training-adaptive {
  &-block {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5rem;
    margin-inline: auto;
    gap: fluid(3rem, 1.5rem);

    &__title {
      text-align: center;
    }

    &__item {
      display: flex;
      flex-grow: 1;
      flex-basis: 34%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;

      @media (max-width: em(660)) {
        flex-basis: 51%;
      }
    }

    &__ico {
      max-height: 11rem;

      img {
        height: 100%;
      }
    }
  }

  .btn-primary {
    margin-top: 3rem;
    margin-inline: auto;
  }
}
