.fssc22000-why {
  &__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3rem;
    gap: 3rem;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 21%;
    flex-direction: column;
    align-items: center;
    max-width: 30rem;
    gap: 2rem;

    @media (max-width: em(1030)) {
      flex-basis: 26%;

      @media (max-width: em(800)) {
        flex-basis: 34%;

        @media (max-width: em(510)) {
          flex-basis: 100%;
        }
      }
    }
  }

  img {
    width: 10rem;
    height: 10rem;
    background-color: #949494;
  }
}
