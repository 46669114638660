.order {
  .h2 {
    margin-bottom: 8rem;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
  }

  &-item {
    position: relative;
    flex-grow: 1;
    flex-basis: 34%;
    border-radius: 0.8rem;
    padding: 4rem 2rem 2rem;
    padding-top: 4rem;
    box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);

    &__num {
      position: absolute;
      left: 2rem;
      top: 0;
      font-weight: 900;
      font-size: fluid(6rem, 3rem);
      color: var(--clr-primary-100);
      opacity: 0.3;
      transform: translateY(-50%);
    }

    &__text {
      margin-top: 1rem;
    }
  }
}
