h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-title-100);
  font-weight: bold;
  text-transform: uppercase;
}

.h1 {
  margin-bottom: fluid(4rem, 2rem);
  font-size: fluid(3.6rem, 2.2rem);
  color: var(--clr-secondary-200);
}

.h2 {
  margin-bottom: fluid(4rem, 2rem);
  font-size: fluid(3.6rem, 1.9rem);
  color: var(--clr-secondary-200);
}

.h3 {
  margin-bottom: fluid(4rem, 2rem);
  font-size: fluid(3rem, 2.2rem);
}

.h4 {
  margin-bottom: fluid(4rem, 2rem);
  font-size: fluid(2.4rem, 2rem);
}

.h5 {
  margin-bottom: fluid(4rem, 2rem);
  font-size: fluid(2rem, 1.8rem);
}

.h6 {
  margin-bottom: fluid(4rem, 2rem);
  font-size: fluid(1.6rem, 1.4rem);
}

.text-14 {
  font-size: 1.4rem;
}

.text {
  font-size: fluid(1.6rem, 1.4rem);
}

.text-18 {
  font-size: fluid(1.8rem, 1.6rem);
}

.text-20 {
  font-size: fluid(2rem, 1.8rem);
}

.text-22 {
  font-size: fluid(2.2rem, 2rem);
}

.text-24 {
  font-size: fluid(2.4rem, 2.2rem);
}

.text-center {
  text-align: center;
}

.green {
  color: var(--clr-primary-100);
}

.purple {
  color: var(--clr-secondary-100);
}

.tdu-green {
  text-decoration: underline;
  text-decoration-color: var(--clr-primary-100); /* Цвет подчеркивания */
}

.bor-green {
  border-style: solid;
  border-width: 0.1rem;
  border-color: var(--clr-primary-100);
  border-radius: 0.3rem;
  padding: 0.2rem;
}

.green-bg {
  padding-inline: 0.3rem;

  width: max-content;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-100);
}

.purple-bg {
  padding-inline: 0.3rem;
  width: max-content;
  color: var(--clr-default-100);
  background-color: var(--clr-secondary-100);
}
