.support-audits-details {
  .block-border-green {
    margin-top: 3rem;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    gap: 4rem;

    @media (max-width: em(720)) {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 26%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  &__text {
    @media (max-width: em(720)) {
      margin-inline: auto;
      max-width: 45rem;
    }
  }

  img {
    height: 10rem;
  }
}
