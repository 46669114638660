.subscribe-service-how {
  &__block {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5rem;
    gap: 3rem;
  }

  &__item {
    position: relative;
    flex-grow: 1;
    flex-basis: 21%;
    border-radius: 0.8rem;
    padding: 4rem 2rem 2rem;
    padding-top: 4rem;
    box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);

    @media (max-width: em(930)) {
      flex-basis: 34%;

      @media (max-width: em(520)) {
        flex-basis: 100%;
      }
    }
  }

  &__num {
    position: absolute;
    left: 2rem;
    top: 0;
    font-weight: 900;
    font-size: clamp(3rem, 2.0204081633rem + 3.0612244898vw, 6rem);
    color: var(--clr-primary-100);
    opacity: 0.3;
    transform: translateY(-50%);
  }
}
