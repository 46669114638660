.corporate-training-companies {
  &__block {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.8rem;
    aspect-ratio: 1/1;
  }
}
