.services {
  &-block {
    display: flex;
    flex-wrap: wrap;
    gap: fluid(3rem, 1.5rem);

    &__item {
      position: relative;
      display: flex;
      flex-grow: 1;
      flex-basis: 34%;
      flex-direction: column;
      justify-content: center;
      padding: fluid(3rem, 2rem);
      min-height: fluid(30rem, 20rem);
      cursor: pointer;

      @media (max-width: em(980)) {
        flex-basis: 100%;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        display: flex;
        border-style: solid;
        border-width: 0rem;
        border-color: transparent;
        width: 100.1%;
        height: 100.1%;
        background-color: rgba($color: #000000, $alpha: 0.6);
        transform: translate(-50%, -50%);
        transition: transform 0.5s, border-color 0.2s, border-width 0.2s;
      }

      &:hover::before {
        border-style: solid;
        border-width: 0.5rem;
        border-color: var(--clr-primary-100);
      }

      &_1 {
        background-image: url("../img/index/services/services-1.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      &_2 {
        background-image: url("../img/index/services/services-2.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      &_3 {
        background-image: url("../img/index/services/services-3.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      &_4 {
        background-image: url("../img/index/services/services-4.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    &__title {
      position: relative;
      z-index: 1;
      max-width: 95%;
      font-size: fluid(1.9rem, 1.8rem);
      color: var(--clr-default-100);
    }

    &__text {
      position: relative;
      z-index: 1;
      margin-top: fluid(1.8rem, 1rem);
      font-size: fluid(1.6rem, 1.4rem);
      color: var(--clr-default-100);
    }
  }
}
