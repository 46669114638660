.iso22000-companies {
  &__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: center;
    margin-top: 3rem;
    gap: 4rem;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 21%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    @media (max-width: em(860)) {
      flex-basis: 26%;

      @media (max-width: em(610)) {
        flex-basis: 31%;

        @media (max-width: em(460)) {
          flex-basis: 51%;
        }
      }
    }
  }

  img {
    width: 10rem;
    height: 10rem;
    background-color: #898989;
  }
}
