.subscribe-service-include {
  .h2 {
    margin-top: 3rem;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 3rem;

    @media (max-width: em(750)) {
      flex-direction: column;
    }

    &_reverse {
      @media (max-width: em(750)) {
        flex-direction: column-reverse;
      }
      .subscribe-service-include__info {
        order: -1;
      }
    }
  }

  &__img {
    img {
      width: 35rem;

      @media (max-width: em(750)) {
        width: 100%;
      }
    }
  }

  &__info {
    flex-grow: 1;
    flex-basis: 50%;
  }

  &__caption {
    font-weight: 700;
  }

  &__text {
    margin-top: 1rem;
  }

  .btn-primary {
    margin-inline: auto;
  }
}
