.iso9001-advantages {
  .h2 {
    margin-top: 4rem;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 26%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    @media (max-width: em(730)) {
      flex-basis: 34%;

      @media (max-width: em(475)) {
        flex-basis: 100%;
      }
    }
  }

  p {
    margin-bottom: 1rem;
  }

  img {
    width: 10rem;
    height: 10rem;
    background-color: #787878;
  }
}
