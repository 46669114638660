.management-system-hero {
  position: relative;
  display: flex;
  height: calc(100vh - 12.5rem);
  background-image: url("../img/bg-main.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
  }

  .container {
    display: flex;
    flex-grow: 1;
  }

  &__wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .h1 {
    margin-bottom: 0;
    text-align: center;
    color: var(--clr-default-100);
  }

  &__subtitle {
    max-width: 85rem;
    font-size: fluid(2rem, 1.8rem);
    text-align: center;
    color: var(--clr-default-100);
  }
}
