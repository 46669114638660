.team {
  &__block {
    display: flex;
    gap: 3rem;

    @media (max-width: em(910)) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 26%;
    flex-direction: column;
    align-items: center;
    border-radius: 0.8rem;
    padding: fluid(2rem, 1rem);
    box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);
    gap: 2rem;

    @media (max-width: em(910)) {
      max-width: 50rem;
    }
  }

  &__img {
    position: relative;
    overflow: hidden;
    border-radius: 0.6rem;
    width: 100%;
    height: 40rem;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    font-weight: 700;
    text-align: center;
  }
}
