.corporate-training-features {
  &__block {
    display: flex;
    flex-wrap: wrap;
    margin-inline: auto;
    max-width: 100rem;
    gap: 2rem;
  }

  &-item {
    display: flex;
    flex-grow: 1;
    flex-basis: 26%;
    flex-direction: column;
    align-items: center;
    border-radius: 0.8rem;
    padding: fluid(2rem, 1.3rem);
    box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);
    gap: 1rem;

    @media (max-width: em(780)) {
      flex-basis: 34%;

      @media (max-width: em(490)) {
        flex-basis: 100%;
      }
    }

    &__ico {
      max-height: 10rem;

      img {
        height: 100%;
      }
    }

    &__text {
      max-width: 28rem;
      text-align: center;
    }
  }

  .btn-primary {
    margin-top: 4rem;
    margin-inline: auto;
  }
}
