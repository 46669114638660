.iso22000-steps {
  &__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 4rem;
    gap: 4rem;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 21%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    @media (max-width: em(950)) {
      flex-basis: 26%;

      @media (max-width: em(680)) {
        flex-basis: 34%;

        @media (max-width: em(460)) {
          flex-basis: 100%;
        }
      }
    }
  }

  img {
    width: 10rem;
    height: 10rem;
    background-color: #6e6e6e;
  }
}
