@charset "UTF-8";

/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNextCyr-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNextCyr-Demi.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNextCyr-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNextCyr-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNextCyr-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNextCyr-Thin.woff2") format("woff2");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNextCyr-UltraLight.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "druktextwidecyr";
  src: url("../fonts/druktextwidecyr-bold.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family-main-100);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: auto 1px;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-weight: 400;
  --content-width: 127rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;
  --font-family-main-100: "Manrope";
  --font-family-title-100: "EB Garamond";
  --clr-default-100: #fff;
  --clr-default-400: #181818;
  --clr-default-900: #000;
  --clr-primary-100: #4caf4f;
  --clr-secondary-100: #6a0572;
  --clr-secondary-200: #5d1e84;
  --clr-dark-100: #292929;
}

body {
  font-size: 1.6rem;
  color: var(--clr-default-400);
  /* scroll bar */
}

body * {
  text-align: justify;
}

body.lock {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

body::-webkit-scrollbar {
  width: 0.7rem;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--clr-primary-100);
}

body::-webkit-scrollbar-track {
  background-color: var(--clr-default-100);
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

main {
  flex-grow: 1;
}

.container {
  margin-inline: auto;
  padding-inline: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.section {
  padding-top: clamp(7rem, 6.0204081633rem + 3.0612244898vw, 10rem);
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.block-border-green {
  border-style: solid;
  border-width: 0.2rem;
  border-color: var(--clr-primary-100);
  border-radius: 0.8rem;
  padding: 2rem 5rem;
  font-size: clamp(1.8rem, 1.6693877551rem + 0.4081632653vw, 2.2rem);
}

.about__block {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.about__text {
  max-width: 75rem;
  font-size: clamp(1.8rem, 1.6693877551rem + 0.4081632653vw, 2.2rem);
}

.about__text p:not(:first-child) {
  margin-top: 1.5rem;
}

.about__img {
  position: relative;
  min-width: 40rem;
  aspect-ratio: 10/12;
}

.about__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-info {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  gap: 3rem;
}

.about-info__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  padding: 1.5rem 4rem;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);
  gap: 1rem;
}

.about-info__item span {
  font-weight: 700;
  font-size: clamp(3rem, 2.0204081633rem + 3.0612244898vw, 6rem);
  text-align: center;
  color: var(--clr-primary-100);
}

.about-info__item span:not(:first-child) {
  font-weight: 400;
  font-size: clamp(1.6rem, 1.3714285714rem + 0.7142857143vw, 2.3rem);
  color: var(--clr-default-900);
}

.why__block {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}

.why-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.8rem;
  padding: clamp(2rem, 1.5428571429rem + 1.4285714286vw, 3.4rem) clamp(1.5rem, 0.6183673469rem + 2.7551020408vw, 4.2rem);
  box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);
}

.why-item__ico {
  max-height: 10rem;
}

.why-item__ico img {
  height: 100%;
}

.why-item__title {
  margin-top: 1.6rem;
  text-align: center;
}

.why-item__text {
  margin-top: 0.8rem;
}

.cases {
  position: relative;
  overflow: hidden;
}

.cases__block {
  display: flex;
  align-items: center;
  white-space: nowrap;
  animation: scrollCases 15s linear infinite;
  gap: 4rem;
}

@keyframes scrollCases {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

.cases__item {
  flex-shrink: 0;
}

.cases__item img {
  height: auto;
  min-width: 10rem;
  max-width: 20rem;
  filter: grayscale(100%);
  transition: filter var(--transition);
  cursor: pointer;
}

.cases__item img:hover {
  filter: grayscale(0);
}

.cases__block::after {
  content: "";
  display: block;
  flex-shrink: 0;
  width: 100%;
}

.menu__list {
  display: flex;
  align-items: center;
}

.menu__item:not(:last-child) {
  margin-right: 1.5rem;
}

.menu__link {
  position: relative;
  display: inline-block;
  font-size: clamp(1.4rem, 1.3346938776rem + 0.2040816327vw, 1.6rem);
  color: var(--clr-default-400);
}

.menu__link::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  display: inline-block;
  width: 0;
  height: 0.1rem;
  background-color: var(--clr-default-400);
  transform: translate(-50%, 0.2rem);
  transition: width var(--transition);
}

.menu__link:hover::after {
  width: 100%;
}

.burger {
  display: none;
}

.input-text {
  position: relative;
  width: 19rem;
}

.input-text input {
  display: block;
  border: 0;
  border-bottom: 0.2rem var(--clr-default-900) solid;
  padding: 1.5rem 0;
  width: 100%;
  font-size: 1.8rem;
  color: var(--clr-default-900);
  background-color: transparent;
}

.input-text input:focus,
.input-text input:valid {
  outline: 0;
  border-bottom-color: var(--clr-default-900);
}

.input-text label {
  position: absolute;
  left: 0;
  top: 1.5rem;
  pointer-events: none;
}

.input-text label span {
  display: inline-block;
  min-width: 0.5rem;
  font-size: 1.8rem;
  color: var(--clr-default-900);
  transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.input-text input:focus + label span,
.input-text input:valid + label span {
  color: var(--clr-default-900);
  transform: translateY(-3rem);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-title-100);
  font-weight: bold;
  text-transform: uppercase;
}

.h1 {
  margin-bottom: clamp(2rem, 1.3469387755rem + 2.0408163265vw, 4rem);
  font-size: clamp(2.2rem, 1.7428571429rem + 1.4285714286vw, 3.6rem);
  color: var(--clr-secondary-200);
}

.h2 {
  margin-bottom: clamp(2rem, 1.3469387755rem + 2.0408163265vw, 4rem);
  font-size: clamp(1.9rem, 1.3448979592rem + 1.7346938776vw, 3.6rem);
  color: var(--clr-secondary-200);
}

.h3 {
  margin-bottom: clamp(2rem, 1.3469387755rem + 2.0408163265vw, 4rem);
  font-size: clamp(2.2rem, 1.9387755102rem + 0.8163265306vw, 3rem);
}

.h4 {
  margin-bottom: clamp(2rem, 1.3469387755rem + 2.0408163265vw, 4rem);
  font-size: clamp(2rem, 1.8693877551rem + 0.4081632653vw, 2.4rem);
}

.h5 {
  margin-bottom: clamp(2rem, 1.3469387755rem + 2.0408163265vw, 4rem);
  font-size: clamp(1.8rem, 1.7346938776rem + 0.2040816327vw, 2rem);
}

.h6 {
  margin-bottom: clamp(2rem, 1.3469387755rem + 2.0408163265vw, 4rem);
  font-size: clamp(1.4rem, 1.3346938776rem + 0.2040816327vw, 1.6rem);
}

.text-14 {
  font-size: 1.4rem;
}

.text {
  font-size: clamp(1.4rem, 1.3346938776rem + 0.2040816327vw, 1.6rem);
}

.text-18 {
  font-size: clamp(1.6rem, 1.5346938776rem + 0.2040816327vw, 1.8rem);
}

.text-20 {
  font-size: clamp(1.8rem, 1.7346938776rem + 0.2040816327vw, 2rem);
}

.text-22 {
  font-size: clamp(2rem, 1.9346938776rem + 0.2040816327vw, 2.2rem);
}

.text-24 {
  font-size: clamp(2.2rem, 2.1346938776rem + 0.2040816327vw, 2.4rem);
}

.text-center {
  text-align: center;
}

.green {
  color: var(--clr-primary-100);
}

.purple {
  color: var(--clr-secondary-100);
}

.tdu-green {
  text-decoration: underline;
  text-decoration-color: var(--clr-primary-100);
  /* Цвет подчеркивания */
}

.bor-green {
  border-style: solid;
  border-width: 0.1rem;
  border-color: var(--clr-primary-100);
  border-radius: 0.3rem;
  padding: 0.2rem;
}

.green-bg {
  padding-inline: 0.3rem;
  width: max-content;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-100);
}

.purple-bg {
  padding-inline: 0.3rem;
  width: max-content;
  color: var(--clr-default-100);
  background-color: var(--clr-secondary-100);
}

.btn-primary {
  display: flex;
  border: 0.1rem solid var(--clr-primary-100);
  border-radius: 0.8rem;
  padding: 1.5rem 4.5rem;
  font-weight: 500;
  font-size: 1.7rem;
  color: #181818;
  color: var(--clr-default-100);
  background: var(--clr-primary-100);
  filter: drop-shadow(0.2rem 0.2rem 0.3rem rgba(0, 0, 0, 0.2));
  cursor: pointer;
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 2rem;
}

.footer__wrapper {
  display: flex;
}

.faq .h2 {
  color: var(--clr-default-900);
}

.faq__list {
  margin-top: 2rem;
}

.faq__title {
  position: relative;
  border-radius: 0.8rem;
  padding: 2rem 10rem 2rem 4rem;
  width: 100%;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);
  font-size: clamp(1.8rem, 1.7346938776rem + 0.2040816327vw, 2rem);
  line-height: 135%;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-100);
  cursor: pointer;
}

.faq__title::before {
  content: "";
  position: absolute;
  right: 5rem;
  top: 50%;
  width: 0.1rem;
  height: 2rem;
  background-color: var(--clr-default-100);
  transform: translateY(-50%);
  transition: height var(--transition);
}

.faq__title::after {
  content: "";
  position: absolute;
  right: 4rem;
  top: 50%;
  width: 2rem;
  height: 0.1rem;
  background-color: var(--clr-default-100);
  transform: translateY(-50%);
}

.faq__item {
  background-color: var(--clr-bg-100);
}

.faq__item-body {
  overflow: hidden;
  max-height: 0;
  box-sizing: content-box;
  line-height: 160%;
  transition: max-height var(--transition);
}

.faq__item-body p {
  margin-top: 0;
  padding: 2rem 4rem;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(1.4rem, 1.2693877551rem + 0.4081632653vw, 1.8rem);
  line-height: 155%;
  letter-spacing: 0.036rem;
}

.faq__item:not(:last-child) {
  margin-bottom: 3rem;
  transition: margin var(--transition);
}

.faq__item--active {
  margin-bottom: 0;
}

.faq__item--active:not(:last-child) {
  margin-bottom: 0rem;
}

.faq__item--active .faq__title::before {
  height: 0;
}

.big-form {
  padding-bottom: clamp(4rem, 2.0408163265rem + 6.1224489796vw, 10rem);
}

.big-form__block {
  display: flex;
  justify-content: center;
  margin-inline: auto;
  gap: clamp(1.3rem, 0.4183673469rem + 2.7551020408vw, 4rem);
}

.order .h2 {
  margin-bottom: 8rem;
}

.order__block {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}

.order-item {
  position: relative;
  flex-grow: 1;
  flex-basis: 34%;
  border-radius: 0.8rem;
  padding: 4rem 2rem 2rem;
  padding-top: 4rem;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);
}

.order-item__num {
  position: absolute;
  left: 2rem;
  top: 0;
  font-weight: 900;
  font-size: clamp(3rem, 2.0204081633rem + 3.0612244898vw, 6rem);
  color: var(--clr-primary-100);
  opacity: 0.3;
  transform: translateY(-50%);
}

.order-item__text {
  margin-top: 1rem;
}

.team__block {
  display: flex;
  gap: 3rem;
}

.team__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  border-radius: 0.8rem;
  padding: clamp(1rem, 0.6734693878rem + 1.0204081633vw, 2rem);
  box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);
  gap: 2rem;
}

.team__img {
  position: relative;
  overflow: hidden;
  border-radius: 0.6rem;
  width: 100%;
  height: 40rem;
}

.team__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team__name {
  font-weight: 700;
  text-align: center;
}

.feedback__swiper {
  padding-inline: 12.5rem;
  max-width: 115rem;
}

.feedback .swiper-slide {
  border-radius: 2.4rem;
  padding: 5.2rem;
  min-height: 4rem;
  background: var(--clr-secondary-200);
}

.feedback__caption {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 119%;
  letter-spacing: -0.01em;
  color: var(--clr-default-100);
}

.feedback__text {
  margin-top: 2.4rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 145%;
  color: rgba(255, 255, 255, 0.64);
}

.feedback__text a {
  text-decoration: underline;
  color: var(--clr-default-100);
  text-decoration-skip-ink: none;
}

.feedback__text span {
  color: var(--clr-default-100);
}

.feedback__text p {
  margin-bottom: 1rem;
}

.feedback__happy {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 144%;
  color: rgba(255, 255, 255, 0.8);
}

.feedback__next,
.feedback__prev {
  position: absolute;
  top: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid rgba(25, 152, 165, 0.1);
  border-radius: 1.2rem;
  width: 7rem;
  height: 7rem;
  background-color: rgba(76, 175, 79, 0.05);
  transform: translateY(-50%);
  cursor: pointer;
}

.feedback__prev {
  left: 0;
}

.feedback__next {
  right: 0;
}

.photo-gallery .swiper {
  width: 100%;
  height: 100%;
}

.photo-gallery .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-align: center;
  background: #fff;
}

.photo-gallery .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo-gallery .swiper {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.photo-gallery .swiper-slide {
  background-position: center;
  background-size: cover;
}

.photo-gallery .mySwiper2 {
  width: 100%;
}

.photo-gallery .photoGallery1 {
  padding: 10px 0;
  height: 20%;
  box-sizing: border-box;
}

.photo-gallery .photoGallery1 .swiper-slide {
  width: 25%;
  height: clamp(10rem, 5.1020408163rem + 15.306122449vw, 25rem);
  opacity: 0.4;
}

.photo-gallery .photoGallery1 .swiper-slide-thumb-active {
  opacity: 1;
}

.photo-gallery .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo-gallery .photoGallery2 img {
  height: clamp(30rem, 16.9387755102rem + 40.8163265306vw, 70rem);
}

.hero__wrapper {
  font-size: fluid-font(2rem, 1.4rem);
}

.services-block {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(1.5rem, 1.0102040816rem + 1.5306122449vw, 3rem);
}

.services-block__item {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-basis: 34%;
  flex-direction: column;
  justify-content: center;
  padding: clamp(2rem, 1.6734693878rem + 1.0204081633vw, 3rem);
  min-height: clamp(20rem, 16.7346938776rem + 10.2040816327vw, 30rem);
  cursor: pointer;
}

.services-block__item::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  border-style: solid;
  border-width: 0rem;
  border-color: transparent;
  width: 100.1%;
  height: 100.1%;
  background-color: rgba(0, 0, 0, 0.6);
  transform: translate(-50%, -50%);
  transition: transform 0.5s, border-color 0.2s, border-width 0.2s;
}

.services-block__item:hover::before {
  border-style: solid;
  border-width: 0.5rem;
  border-color: var(--clr-primary-100);
}

.services-block__item_1 {
  background-image: url("../img/index/services/services-1.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.services-block__item_2 {
  background-image: url("../img/index/services/services-2.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.services-block__item_3 {
  background-image: url("../img/index/services/services-3.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.services-block__item_4 {
  background-image: url("../img/index/services/services-4.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.services-block__title {
  position: relative;
  z-index: 1;
  max-width: 95%;
  font-size: clamp(1.8rem, 1.7673469388rem + 0.1020408163vw, 1.9rem);
  color: var(--clr-default-100);
}

.services-block__text {
  position: relative;
  z-index: 1;
  margin-top: clamp(1rem, 0.7387755102rem + 0.8163265306vw, 1.8rem);
  font-size: clamp(1.4rem, 1.3346938776rem + 0.2040816327vw, 1.6rem);
  color: var(--clr-default-100);
}

.contacts__block {
  display: flex;
  gap: clamp(1.5rem, 1.0102040816rem + 1.5306122449vw, 3rem);
}

.contacts-info,
.contacts__map {
  flex-grow: 1;
  flex-basis: 34%;
}

.contacts-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contacts-info__list {
  display: flex;
  flex-direction: column;
  gap: clamp(1rem, 0.6734693878rem + 1.0204081633vw, 2rem);
}

.contacts-info__item *,
.contacts-info__item {
  font-size: clamp(2rem, 1.8367346939rem + 0.5102040816vw, 2.5rem);
  color: var(--clr-default-400);
  transition: color var(--transition);
}

.contacts-info__item a {
  cursor: pointer;
}

.contacts-info__item a:hover {
  color: var(--clr-primary-100);
}

.contacts-socials {
  display: flex;
  margin-top: 2rem;
  gap: clamp(2rem, 1.6734693878rem + 1.0204081633vw, 3rem);
}

.contacts-socials__item {
  width: clamp(4rem, 3.0204081633rem + 3.0612244898vw, 7rem);
  height: clamp(4rem, 3.0204081633rem + 3.0612244898vw, 7rem);
  transition: transform var(--transition);
}

.contacts-socials__item:hover {
  transform: scale(0.9);
}

.contacts-socials__item:hover .contacts-socials-theme {
  fill: var(--clr-primary-100);
  transition: fill var(--transition);
}

.contacts__map {
  min-height: 35rem;
}

.corporate-training-hero {
  position: relative;
  display: flex;
  height: calc(100vh - 12.5rem);
  background-image: url("../img/bg-main.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.corporate-training-hero::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
}

.corporate-training-hero .container {
  display: flex;
  flex-grow: 1;
}

.corporate-training-hero__wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.corporate-training-hero .h1 {
  margin-bottom: 0;
  max-width: 100rem;
  text-align: center;
  color: var(--clr-default-100);
}

.corporate-training-hero__subtitle {
  max-width: 85rem;
  font-size: clamp(1.8rem, 1.7346938776rem + 0.2040816327vw, 2rem);
  text-align: center;
  color: var(--clr-default-100);
}

.corporate-training-if-list {
  display: flex;
  flex-direction: column;
  gap: clamp(1.5rem, 1.0102040816rem + 1.5306122449vw, 3rem);
}

.corporate-training-if-list__item {
  display: flex;
  margin-inline: auto;
  max-width: 90rem;
  font-size: clamp(1.8rem, 1.6693877551rem + 0.4081632653vw, 2.2rem);
  text-align: justify;
  gap: 1rem;
}

.corporate-training-if-list__item::before {
  content: "✓";
  display: flex;
  color: var(--clr-primary-100);
}

.corporate-training-if .block-border-green {
  margin-top: 4rem;
}

.corporate-training-adaptive-block {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
  margin-inline: auto;
  gap: clamp(1.5rem, 1.0102040816rem + 1.5306122449vw, 3rem);
}

.corporate-training-adaptive-block__title {
  text-align: center;
}

.corporate-training-adaptive-block__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 34%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.corporate-training-adaptive-block__ico {
  max-height: 11rem;
}

.corporate-training-adaptive-block__ico img {
  height: 100%;
}

.corporate-training-adaptive .btn-primary {
  margin-top: 3rem;
  margin-inline: auto;
}

.corporate-training-companies__block {
  display: flex;
  justify-content: space-between;
}

.corporate-training-companies__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  aspect-ratio: 1/1;
}

.corporate-training-features__block {
  display: flex;
  flex-wrap: wrap;
  margin-inline: auto;
  max-width: 100rem;
  gap: 2rem;
}

.corporate-training-features-item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  border-radius: 0.8rem;
  padding: clamp(1.3rem, 1.0714285714rem + 0.7142857143vw, 2rem);
  box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);
  gap: 1rem;
}

.corporate-training-features-item__ico {
  max-height: 10rem;
}

.corporate-training-features-item__ico img {
  height: 100%;
}

.corporate-training-features-item__text {
  max-width: 28rem;
  text-align: center;
}

.corporate-training-features .btn-primary {
  margin-top: 4rem;
  margin-inline: auto;
}

.management-system-hero {
  position: relative;
  display: flex;
  height: calc(100vh - 12.5rem);
  background-image: url("../img/bg-main.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.management-system-hero::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
}

.management-system-hero .container {
  display: flex;
  flex-grow: 1;
}

.management-system-hero__wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.management-system-hero .h1 {
  margin-bottom: 0;
  text-align: center;
  color: var(--clr-default-100);
}

.management-system-hero__subtitle {
  max-width: 85rem;
  font-size: clamp(1.8rem, 1.7346938776rem + 0.2040816327vw, 2rem);
  text-align: center;
  color: var(--clr-default-100);
}

.management-system-advantages__title {
  margin-top: 2rem;
}

.management-system-advantages__block {
  display: flex;
  flex-wrap: wrap;
}

.management-system-advantages__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
}

.management-system-advantages__caption {
  min-height: 3.6rem;
  font-size: 1.6rem;
}

.management-system-advantages__text {
  display: flex;
  flex-grow: 1;
}

.management-system-list__block {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}

.management-system-list__item {
  display: flex;
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.management-system-list__item img {
  height: 10rem;
}

.management-system-how__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 4rem;
}

.management-system-how__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.management-system-how__item img {
  height: 10rem;
}

.management-system-choice__block {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  gap: 3rem;
}

.management-system-choice__item {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}

.management-system-choice__info {
  flex-basis: 60%;
}

.management-system-choice__text {
  margin-top: 1rem;
}

.management-system-choice__img img {
  width: 40rem;
}

.support-audits-hero {
  position: relative;
  display: flex;
  height: calc(100vh - 12.5rem);
  background-image: url("../img/bg-main.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.support-audits-hero::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
}

.support-audits-hero .container {
  display: flex;
  flex-grow: 1;
}

.support-audits-hero__wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.support-audits-hero .h1 {
  margin-bottom: 0;
  max-width: 100%;
  font-size: clamp(1.8rem, 1.212244898rem + 1.8367346939vw, 3.6rem);
  text-align: center;
  color: var(--clr-default-100);
}

.support-audits-different .h2 {
  margin-top: 2rem;
}

.support-audits-different__block {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.support-audits-different__item {
  display: flex;
  gap: 3rem;
}

.support-audits-different__info {
  display: flex;
  flex-grow: 1;
  flex-basis: 60%;
  flex-direction: column;
  gap: 1.5rem;
}

.support-audits-different__caption {
  font-size: clamp(1.6rem, 1.306122449rem + 0.9183673469vw, 2.5rem);
}

.support-audits-different__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.support-audits-different__text span {
  font-weight: 700;
}

.support-audits-different__img {
  position: relative;
  flex-grow: 1;
  width: 40rem;
  aspect-ratio: 16/9;
}

.support-audits-different__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.support-audits-mandatory__block {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}

.support-audits-mandatory__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 34%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.support-audits-mandatory__img img {
  height: 10rem;
}

.support-audits-mandatory__caption {
  text-align: center;
}

.support-audits-details .block-border-green {
  margin-top: 3rem;
}

.support-audits-details__block {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
}

.support-audits-details__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.support-audits-details img {
  height: 10rem;
}

.subscribe-service-hero {
  position: relative;
  display: flex;
  height: calc(100vh - 12.5rem);
  background-image: url("../img/bg-main.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.subscribe-service-hero::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
}

.subscribe-service-hero .container {
  display: flex;
  flex-grow: 1;
}

.subscribe-service-hero__wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.subscribe-service-hero .h1 {
  margin-bottom: 0;
  text-align: center;
  color: var(--clr-default-100);
}

.subscribe-service-hero__subtitle {
  max-width: 85rem;
  font-size: clamp(1.8rem, 1.7346938776rem + 0.2040816327vw, 2rem);
  text-align: center;
  color: var(--clr-default-100);
}

.subscribe-service-include .h2 {
  margin-top: 3rem;
}

.subscribe-service-include__block {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.subscribe-service-include__item {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.subscribe-service-include__item_reverse .subscribe-service-include__info {
  order: -1;
}

.subscribe-service-include__img img {
  width: 35rem;
}

.subscribe-service-include__info {
  flex-grow: 1;
  flex-basis: 50%;
}

.subscribe-service-include__caption {
  font-weight: 700;
}

.subscribe-service-include__text {
  margin-top: 1rem;
}

.subscribe-service-include .btn-primary {
  margin-inline: auto;
}

.subscribe-service-who__block {
  display: flex;
  margin-top: 3rem;
  gap: 3rem;
}

.subscribe-service-who__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  border-radius: 0.8rem;
  padding: clamp(1rem, 0.6734693878rem + 1.0204081633vw, 2rem);
  box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);
  gap: 1rem;
}

.subscribe-service-who img {
  height: 10rem;
}

.subscribe-service-who__caption {
  font-weight: 700;
}

.subscribe-service-who .block-border-green {
  margin-top: 2rem;
}

.subscribe-service-result__block {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}

.subscribe-service-result__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 34%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.subscribe-service-result img {
  height: 10rem;
}

.subscribe-service-result__caption {
  font-weight: 700;
}

.subscribe-service-result__text {
  max-width: 50rem;
}

.subscribe-service-how__block {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
  gap: 3rem;
}

.subscribe-service-how__item {
  position: relative;
  flex-grow: 1;
  flex-basis: 21%;
  border-radius: 0.8rem;
  padding: 4rem 2rem 2rem;
  padding-top: 4rem;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);
}

.subscribe-service-how__num {
  position: absolute;
  left: 2rem;
  top: 0;
  font-weight: 900;
  font-size: clamp(3rem, 2.0204081633rem + 3.0612244898vw, 6rem);
  color: var(--clr-primary-100);
  opacity: 0.3;
  transform: translateY(-50%);
}

.iso22000-target .h2 {
  margin-top: 4rem;
}

.iso22000-target__block {
  display: flex;
  justify-content: space-between;
  gap: clamp(3rem, 2.0204081633rem + 3.0612244898vw, 6rem);
}

.iso22000-target__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.iso22000-target img {
  width: 10rem;
  height: 10rem;
  background-color: #8a7c7c;
}

.iso22000-target .block-border-green {
  margin-top: 3rem;
}

.iso22000-why__block {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}

.iso22000-why__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.iso22000-why img {
  width: 10rem;
  height: 10rem;
  background-color: #878787;
}

.iso22000-companies__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: center;
  margin-top: 3rem;
  gap: 4rem;
}

.iso22000-companies__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 21%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.iso22000-companies img {
  width: 10rem;
  height: 10rem;
  background-color: #898989;
}

.iso22000-steps__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 4rem;
  gap: 4rem;
}

.iso22000-steps__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 21%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.iso22000-steps img {
  width: 10rem;
  height: 10rem;
  background-color: #6e6e6e;
}

.iso22000-optimize__block {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4rem;
  gap: 3rem;
}

.iso22000-optimize__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.iso22000-optimize__text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.iso22000-optimize img {
  width: 10rem;
  height: 10rem;
  background-color: #7e7e7e;
}

.fssc22000-why__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3rem;
  gap: 3rem;
}

.fssc22000-why__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 21%;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;
  gap: 2rem;
}

.fssc22000-why img {
  width: 10rem;
  height: 10rem;
  background-color: #949494;
}

.fssc22000-who__block {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.fssc22000-who__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.fssc22000-who img {
  width: 10rem;
  height: 10rem;
}

.fssc22000-who .block-border-green {
  margin-top: 4rem;
}

.fssc22000-who .btn-primary {
  margin-top: 2rem;
  margin-inline: auto;
}

.fssc22000-steps__block {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.fssc22000-steps__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 21%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.fssc22000-steps img {
  width: 10rem;
  height: 10rem;
}

.fssc22000-what__block {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}

.fssc22000-what__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.fssc22000-what .btn-primary {
  margin-top: 4rem;
  margin-inline: auto;
}

.fssc22000-implement__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.fssc22000-implement__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  gap: 2rem;
}

.fssc22000-implement img {
  width: 10rem;
  height: 10rem;
}

.gmp-advantages .h2 {
  margin-top: 4rem;
}

.gmp-advantages__block {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}

.gmp-advantages__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.gmp-advantages img {
  width: 10rem;
  height: 10rem;
  background-color: #838383;
}

.gmp-why__block {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}

.gmp-why__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.gmp-why img {
  width: 10rem;
  height: 10rem;
  background-color: #8d8d8d;
}

.gmp-companies__info {
  margin-top: 3rem;
}

.gmp-companies__block {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  gap: 4rem;
}

.gmp-companies__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.gmp-companies img {
  width: 10rem;
  height: 10rem;
  background-color: #7d7d7d;
}

.gmp-how__block {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.gmp-how__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 21%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.gmp-how__num {
  font-weight: 900;
  font-size: clamp(3rem, 2.0204081633rem + 3.0612244898vw, 6rem);
  color: var(--clr-primary-100);
  opacity: 0.3;
}

.gmp-how .block-border-green {
  margin-top: 4rem;
}

.brc-substandards .h2 {
  margin-top: 4rem;
}

.brc-substandards__block {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}

.brc-substandards__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 13%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.brc-substandards img {
  width: 10rem;
  height: 10rem;
  background-color: #787878;
}

.brc-advantages__block {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.brc-advantages__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.brc-advantages img {
  width: 10rem;
  height: 10rem;
  background-color: #8b8b8b;
}

.brc-steps__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
}

.brc-steps__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 21%;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  gap: 2rem;
}

.brc-steps__num {
  font-weight: 900;
  font-size: clamp(3rem, 2.0204081633rem + 3.0612244898vw, 6rem);
  color: var(--clr-primary-100);
  opacity: 0.3;
}

.brc-why__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
}

.brc-why__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.brc-why__item p {
  padding-bottom: 1rem;
}

.brc-why img {
  width: 10rem;
  height: 10rem;
}

.ifs-new .h2 {
  margin-top: 4rem;
}

.ifs-new__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
}

.ifs-new__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.ifs-new img {
  width: 10rem;
  height: 10rem;
}

.ifs-steps__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
}

.ifs-steps__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 21%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.ifs-steps img {
  width: 10rem;
  height: 10rem;
}

.ifs-advantages__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
}

.ifs-advantages__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.ifs-advantages img {
  width: 10rem;
  height: 10rem;
}

.ifs-advantages .btn-primary {
  margin-top: 4rem;
  margin-inline: auto;
}

.ifs-who__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
}

.ifs-who__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.ifs-who img {
  width: 10rem;
  height: 10rem;
}

.ifs-info__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
}

.ifs-info__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.ifs-info img {
  width: 10rem;
  height: 10rem;
}

.iso9001-advantages .h2 {
  margin-top: 4rem;
}

.iso9001-advantages__block {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}

.iso9001-advantages__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.iso9001-advantages p {
  margin-bottom: 1rem;
}

.iso9001-advantages img {
  width: 10rem;
  height: 10rem;
  background-color: #787878;
}

.iso9001-who__block {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.iso9001-who__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.iso9001-who img {
  width: 10rem;
  height: 10rem;
  background-color: #8b8b8b;
}

.iso9001-who .btn-primary {
  margin-top: 4rem;
  margin-inline: auto;
}

.iso9001-steps__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
}

.iso9001-steps__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 21%;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  gap: 2rem;
}

.iso9001-steps img {
  width: 10rem;
  height: 10rem;
}

.iso9001-steps__num {
  font-weight: 900;
  font-size: clamp(3rem, 2.0204081633rem + 3.0612244898vw, 6rem);
  color: var(--clr-primary-100);
  opacity: 0.3;
}

.iso9001-features__block {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.iso9001-features__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.iso9001-features img {
  width: 10rem;
  height: 10rem;
  background-color: #8b8b8b;
}

.iso9001-why__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
}

.iso9001-why__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.iso9001-why__item p {
  padding-bottom: 1rem;
}

.iso9001-why img {
  width: 10rem;
  height: 10rem;
}

@media (max-width: 62em) {
  .menu__list {
    flex-direction: column;
    margin: auto;
    padding-block: 4rem;
  }

  .menu__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 3rem;
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-900);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    transition: right var(--transition);
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: hsl(0, 0%, 0%);
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }
}

@media (max-width: 60em) {
  .brc-advantages__item {
    flex-basis: 34%;
  }

  .iso9001-who__item {
    flex-basis: 34%;
  }

  .iso9001-features__item {
    flex-basis: 34%;
  }
}

@media (max-width: 60em) and (max-width: 45em) {
  .brc-advantages__item {
    flex-basis: 100%;
  }

  .iso9001-who__item {
    flex-basis: 100%;
  }

  .iso9001-features__item {
    flex-basis: 100%;
  }
}

@media (max-width: 50em) {
  .fssc22000-what__item {
    flex-basis: 34%;
  }
}

@media (max-width: 50em) and (max-width: 34.375em) {
  .fssc22000-what__item {
    flex-basis: 100%;
  }
}

@media (max-width: 45em) {
  .support-audits-details__block {
    flex-direction: column;
  }

  .support-audits-details__text {
    margin-inline: auto;
    max-width: 45rem;
  }
}

@media (max-width: 36em) {
  .faq__title {
    padding: 2rem 6rem 2rem 2rem;
  }

  .faq__title::before {
    right: 3.5rem;
  }

  .faq__title::after {
    right: 2.5rem;
  }

  .faq__item-body p {
    padding: 2rem;
  }
}

@media (max-width: 25em) {
  .feedback__text {
    font-size: 1.3rem;
  }
}

@media (max-width: 70.625em) {
  .about__block {
    flex-direction: column-reverse;
  }
}

@media (max-width: 68.75em) {
  .brc-substandards__item {
    flex-basis: 26%;
  }
}

@media (max-width: 68.75em) and (max-width: 45.625em) {
  .brc-substandards__item {
    flex-basis: 34%;
  }
}

@media (max-width: 68.75em) and (max-width: 45.625em) and (max-width: 29.6875em) {
  .brc-substandards__item {
    flex-basis: 100%;
  }
}

@media (max-width: 67.1875em) {
  .management-system-advantages__item {
    flex-basis: 34%;
  }
}

@media (max-width: 67.1875em) and (max-width: 37.5em) {
  .management-system-advantages__item {
    flex-basis: 100%;
  }
}

@media (max-width: 66.25em) {
  .brc-steps__item {
    flex-basis: 26%;
  }

  .iso9001-steps__item {
    flex-basis: 26%;
  }
}

@media (max-width: 66.25em) and (max-width: 50.625em) {
  .brc-steps__item {
    flex-basis: 34%;
  }

  .iso9001-steps__item {
    flex-basis: 34%;
  }
}

@media (max-width: 66.25em) and (max-width: 50.625em) and (max-width: 33.75em) {
  .brc-steps__item {
    flex-basis: 100%;
  }

  .iso9001-steps__item {
    flex-basis: 100%;
  }
}

@media (max-width: 65.625em) {
  .why__block {
    flex-wrap: wrap;
    justify-content: center;
  }

  .why-item {
    flex-basis: 40%;
  }

  .ifs-steps__item {
    flex-basis: 26%;
  }
}

@media (max-width: 65.625em) and (max-width: 48.75em) {
  .why-item {
    flex-basis: 100%;
  }
}

@media (max-width: 65.625em) and (max-width: 51.25em) {
  .ifs-steps__item {
    flex-basis: 34%;
  }
}

@media (max-width: 65.625em) and (max-width: 51.25em) and (max-width: 36.25em) {
  .ifs-steps__item {
    flex-basis: 100%;
  }
}

@media (max-width: 64.375em) {
  .fssc22000-why__item {
    flex-basis: 26%;
  }
}

@media (max-width: 64.375em) and (max-width: 50em) {
  .fssc22000-why__item {
    flex-basis: 34%;
  }
}

@media (max-width: 64.375em) and (max-width: 50em) and (max-width: 31.875em) {
  .fssc22000-why__item {
    flex-basis: 100%;
  }
}

@media (max-width: 62.5em) {
  .management-system-how__item {
    flex-basis: 34%;
  }
}

@media (max-width: 62.5em) and (max-width: 45em) {
  .management-system-how__item {
    flex-basis: 100%;
  }
}

@media (max-width: 62.1875em) {
  .fssc22000-steps__item {
    flex-basis: 26%;
  }
}

@media (max-width: 62.1875em) and (max-width: 51.25em) {
  .fssc22000-steps__item {
    flex-basis: 34%;
  }
}

@media (max-width: 62.1875em) and (max-width: 51.25em) and (max-width: 31.25em) {
  .fssc22000-steps__item {
    flex-basis: 100%;
  }
}

@media (max-width: 61.25em) {
  .services-block__item {
    flex-basis: 100%;
  }

  .management-system-list__item {
    flex-basis: 34%;
  }
}

@media (max-width: 61.25em) and (max-width: 51.875em) {
  .management-system-list__item {
    flex-basis: 100%;
  }
}

@media (max-width: 59.375em) {
  .iso22000-steps__item {
    flex-basis: 26%;
  }

  .gmp-how__item {
    flex-basis: 26%;
  }
}

@media (max-width: 59.375em) and (max-width: 42.5em) {
  .iso22000-steps__item {
    flex-basis: 34%;
  }
}

@media (max-width: 59.375em) and (max-width: 42.5em) and (max-width: 28.75em) {
  .iso22000-steps__item {
    flex-basis: 100%;
  }
}

@media (max-width: 59.375em) and (max-width: 46.875em) {
  .gmp-how__item {
    flex-basis: 34%;
  }
}

@media (max-width: 59.375em) and (max-width: 46.875em) and (max-width: 33.125em) {
  .gmp-how__item {
    flex-basis: 100%;
  }
}

@media (max-width: 58.125em) {
  .subscribe-service-how__item {
    flex-basis: 34%;
  }

  .iso22000-why__item {
    flex-basis: 34%;
  }
}

@media (max-width: 58.125em) and (max-width: 32.5em) {
  .subscribe-service-how__item {
    flex-basis: 100%;
  }
}

@media (max-width: 58.125em) and (max-width: 33.125em) {
  .iso22000-why__item {
    flex-basis: 51%;
  }
}

@media (max-width: 56.875em) {
  .team__block {
    flex-direction: column;
    align-items: center;
  }

  .team__item {
    max-width: 50rem;
  }

  .ifs-new__item {
    flex-basis: 34%;
    max-width: 40rem;
  }
}

@media (max-width: 56.25em) {
  .fssc22000-implement__item {
    flex-basis: 34%;
  }

  .ifs-advantages__item {
    flex-basis: 34%;
  }

  .ifs-who__item {
    flex-basis: 34%;
  }
}

@media (max-width: 56.25em) and (max-width: 35em) {
  .fssc22000-implement__item {
    flex-basis: 100%;
  }
}

@media (max-width: 56.875em) and (max-width: 38.75em) {
  .ifs-new__item {
    flex-basis: 100%;
  }
}

@media (max-width: 56.25em) and (max-width: 36.875em) {
  .ifs-advantages__item {
    flex-basis: 100%;
  }

  .ifs-who__item {
    flex-basis: 100%;
  }
}

@media (max-width: 53.125em) {
  .block-border-green {
    padding: clamp(1.6rem, 1.4693877551rem + 0.4081632653vw, 2rem);
  }
}

@media (max-width: 53.75em) {
  .iso22000-companies__item {
    flex-basis: 26%;
  }

  .brc-why__item {
    flex-basis: 34%;
    max-width: 40rem;
  }

  .ifs-info__item {
    flex-basis: 34%;
    max-width: 40rem;
  }

  .iso9001-why__item {
    flex-basis: 34%;
    max-width: 40rem;
  }
}

@media (max-width: 53.75em) and (max-width: 38.125em) {
  .iso22000-companies__item {
    flex-basis: 31%;
  }
}

@media (max-width: 53.75em) and (max-width: 38.125em) and (max-width: 28.75em) {
  .iso22000-companies__item {
    flex-basis: 51%;
  }
}

@media (max-width: 53.75em) and (max-width: 33.75em) {
  .brc-why__item {
    flex-basis: 100%;
  }

  .ifs-info__item {
    flex-basis: 100%;
  }

  .iso9001-why__item {
    flex-basis: 100%;
  }
}

@media (max-width: 52.5em) {
  .subscribe-service-who__block {
    flex-direction: column;
    align-items: center;
  }

  .subscribe-service-who__item {
    max-width: 40rem;
  }

  .gmp-why__item {
    flex-basis: 34%;
  }
}

@media (max-width: 52.5em) and (max-width: 35.625em) {
  .gmp-why__item {
    flex-basis: 100%;
  }
}

@media (max-width: 50.625em) {
  .gmp-advantages__item {
    flex-basis: 34%;
  }
}

@media (max-width: 50.625em) and (max-width: 32.5em) {
  .gmp-advantages__item {
    flex-basis: 100%;
  }
}

@media (max-width: 48.75em) {
  .corporate-training-features-item {
    flex-basis: 34%;
  }
}

@media (max-width: 48.75em) and (max-width: 30.625em) {
  .corporate-training-features-item {
    flex-basis: 100%;
  }
}

@media (max-width: 47.9375em) {
  .faq__item:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .faq__item--active:not(:last-child) {
    margin-bottom: 0;
  }
}

@media (max-width: 46.875em) {
  .support-audits-different__item {
    flex-direction: column-reverse;
  }

  .support-audits-different__img {
    margin-inline: auto;
  }

  .subscribe-service-include__item {
    flex-direction: column;
  }

  .subscribe-service-include__item_reverse {
    flex-direction: column-reverse;
  }

  .subscribe-service-include__img img {
    width: 100%;
  }
}

@media (max-width: 45.625em) {
  .iso9001-advantages__item {
    flex-basis: 34%;
  }
}

@media (max-width: 45.625em) and (max-width: 29.6875em) {
  .iso9001-advantages__item {
    flex-basis: 100%;
  }
}

@media (max-width: 43.75em) {
  .about-info {
    flex-direction: column;
    align-items: center;
  }

  .about-info__item {
    width: 80%;
  }
}

@media (max-width: 41.25em) {
  .feedback__swiper {
    padding-inline: 2rem;
  }

  .feedback__next,
  .feedback__prev {
    top: 80%;
  }

  .corporate-training-adaptive-block__item {
    flex-basis: 51%;
  }
}

@media (max-width: 41.25em) and (max-width: 31.25em) {
  .feedback__swiper {
    padding-inline: 0;
  }
}

@media (max-width: 41.875em) {
  .management-system-choice__item {
    flex-direction: column-reverse;
    align-items: center;
  }

  .management-system-choice__caption {
    text-align: center;
  }

  .management-system-choice__img img {
    width: 100%;
  }
}

@media (max-width: 40.625em) {
  .subscribe-service-result__item {
    flex-basis: 100%;
  }

  .iso22000-target__block {
    flex-direction: column;
  }
}

@media (max-width: 39.375em) {
  .support-audits-mandatory__item {
    flex-basis: 100%;
  }
}

@media (max-width: 37.5em) {
  .big-form__block {
    flex-direction: column;
    align-items: center;
  }

  .big-form__block .container-btn {
    margin-top: 2rem;
  }

  .feedback .swiper-slide {
    padding: 3rem;
  }

  .feedback__caption {
    font-size: 2.5rem;
  }

  .fssc22000-who__item {
    flex-basis: 34%;
  }

  .gmp-companies__item {
    flex-basis: 34%;
  }
}

@media (max-width: 37.5em) and (max-width: 25.625em) {
  .fssc22000-who__item {
    flex-basis: 100%;
  }
}

@media (max-width: 37.5em) and (max-width: 23.125em) {
  .gmp-companies__item {
    flex-basis: 100%;
  }
}

@media (max-width: 34.375em) {
  .about__img {
    min-width: 100%;
  }
}