.corporate-training-if {
  &-list {
    display: flex;
    flex-direction: column;
    gap: fluid(3rem, 1.5rem);

    &__item {
      display: flex;
      margin-inline: auto;
      max-width: 90rem;
      font-size: fluid(2.2rem, 1.8rem);
      text-align: justify;
      gap: 1rem;

      &::before {
        content: "✓";
        display: flex;
        color: var(--clr-primary-100);
      }
    }
  }

  .block-border-green {
    margin-top: 4rem;
  }
}
