$burgerMQ: em(992);

.menu {
  &__list {
    display: flex;
    align-items: center;

    @media (max-width: $burgerMQ) {
      flex-direction: column;
      margin: auto;
      padding-block: 4rem;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 1.5rem;

      @media (max-width: $burgerMQ) {
        margin-right: 0;
        margin-bottom: 3rem;
      }
    }
  }

  &__link {
    position: relative;
    display: inline-block;
    font-size: fluid(1.6rem, 1.4rem);
    color: var(--clr-default-400);

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      display: inline-block;
      width: 0;
      height: 0.1rem;
      background-color: var(--clr-default-400);
      transform: translate(-50%, 0.2rem);
      transition: width var(--transition);
    }

    &:hover::after {
      width: 100%;
    }
  }

  @media (max-width: $burgerMQ) {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-900);
    transition: right var(--transition);

    &--active {
      right: 0;
      transition: right var(--transition);
    }
  }
}
