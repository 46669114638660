.iso9001-features {
  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 26%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    @media (max-width: em(960)) {
      flex-basis: 34%;

      @media (max-width: em(720)) {
        flex-basis: 100%;
      }
    }
  }

  img {
    width: 10rem;
    height: 10rem;
    background-color: #8b8b8b;
  }
}
