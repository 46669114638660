.section {
  padding-top: fluid(10rem, 7rem);
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.block-border-green {
  border-style: solid;
  border-width: 0.2rem;
  border-color: var(--clr-primary-100);
  border-radius: 0.8rem;
  padding: 2rem 5rem;
  font-size: clamp(1.8rem, 1.6693877551rem + 0.4081632653vw, 2.2rem);

  @media (max-width: em(850)) {
    padding: fluid(2rem, 1.6rem);
  }
}

.about {
  &__block {
    display: flex;
    align-items: center;
    gap: 3rem;

    @media (max-width: em(1130)) {
      flex-direction: column-reverse;
    }
  }

  &__text {
    max-width: 75rem;
    font-size: fluid(2.2rem, 1.8rem);

    p {
      &:not(:first-child) {
        margin-top: 1.5rem;
      }
    }
  }

  &__img {
    position: relative;
    min-width: 40rem;
    aspect-ratio: 10/12;

    @media (max-width: em(550)) {
      min-width: 100%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-info {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    gap: 3rem;

    @media (max-width: em(700)) {
      flex-direction: column;
      align-items: center;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 0.8rem;
      padding: 1.5rem 4rem;
      box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);
      gap: 1rem;

      @media (max-width: em(700)) {
        width: 80%;
      }

      span {
        font-weight: 700;
        font-size: fluid(6rem, 3rem);
        text-align: center;
        color: var(--clr-primary-100);

        &:not(:first-child) {
          font-weight: 400;
          font-size: fluid(2.3rem, 1.6rem);
          color: var(--clr-default-900);
        }
      }
    }
  }
}

.why {
  &__block {
    display: flex;
    justify-content: space-between;
    gap: 3rem;

    @media (max-width: em(1050)) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.8rem;
    padding: fluid(3.4rem, 2rem) fluid(4.2rem, 1.5rem);
    box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);

    @media (max-width: em(1050)) {
      flex-basis: 40%;

      @media (max-width: em(780)) {
        flex-basis: 100%;
      }
    }

    &__ico {
      max-height: 10rem;

      img {
        height: 100%;
      }
    }

    &__title {
      margin-top: 1.6rem;
      text-align: center;
    }

    &__text {
      margin-top: 0.8rem;
    }
  }
}

.cases {
  position: relative;
  overflow: hidden; // Скрываем горизонтальный скролл

  &__block {
    display: flex;
    align-items: center;
    white-space: nowrap;
    animation: scrollCases 15s linear infinite; // Авто-прокрутка
    gap: 4rem;
  }

  @keyframes scrollCases {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }

  &__item {
    flex-shrink: 0;

    img {
      height: auto;
      min-width: 10rem;
      max-width: 20rem;
      filter: grayscale(100%);
      transition: filter var(--transition);
      cursor: pointer;

      &:hover {
        filter: grayscale(0);
      }
    }
  }

  // Дублируем контент для бесконечной анимации
  &__block::after {
    content: "";
    display: block;
    flex-shrink: 0;
    width: 100%;
  }
}
