.contacts {
  &__block {
    display: flex;
    gap: fluid(3rem, 1.5rem);
  }

  &-info,
  &__map {
    flex-grow: 1;
    flex-basis: 34%;
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__list {
      display: flex;
      flex-direction: column;
      gap: fluid(2rem, 1rem);
    }

    &__item *,
    &__item {
      font-size: fluid(2.5rem, 2rem);
      color: var(--clr-default-400);
      transition: color var(--transition);
    }

    &__item a {
      cursor: pointer;

      &:hover {
        color: var(--clr-primary-100);
      }
    }
  }

  &-socials {
    display: flex;
    margin-top: 2rem;
    gap: fluid(3rem, 2rem);

    &__item {
      width: fluid(7rem, 4rem);
      height: fluid(7rem, 4rem);
      transition: transform var(--transition);

      &:hover {
        transform: scale(0.9);

        .contacts-socials-theme {
          fill: var(--clr-primary-100);
          transition: fill var(--transition);
        }
      }
    }
  }

  &__map {
    min-height: 35rem;
  }
}
