.gmp-how {
  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 21%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    @media (max-width: em(950)) {
      flex-basis: 26%;

      @media (max-width: em(750)) {
        flex-basis: 34%;

        @media (max-width: em(530)) {
          flex-basis: 100%;
        }
      }
    }
  }

  &__num {
    font-weight: 900;
    font-size: clamp(3rem, 2.0204081633rem + 3.0612244898vw, 6rem);
    color: var(--clr-primary-100);
    opacity: 0.3;
  }

  .block-border-green {
    margin-top: 4rem;
  }
}
