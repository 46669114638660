.ifs-new {
  .h2 {
    margin-top: 4rem;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4rem;
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 26%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    @media (max-width: em(910)) {
      flex-basis: 34%;
      max-width: 40rem;

      @media (max-width: em(620)) {
        flex-basis: 100%;
      }
    }
  }

  img {
    width: 10rem;
    height: 10rem;
  }
}
