.photo-gallery {
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    text-align: center;
    background: #fff;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
  }

  .mySwiper2 {
    width: 100%;
  }

  .photoGallery1 {
    padding: 10px 0;
    height: 20%;
    box-sizing: border-box;
  }

  .photoGallery1 .swiper-slide {
    width: 25%;
    height: fluid(25rem, 10rem);
    opacity: 0.4;
  }

  .photoGallery1 .swiper-slide-thumb-active {
    opacity: 1;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .photoGallery2 {
    img {
      height: fluid(70rem, 30rem);
    }
  }
}
