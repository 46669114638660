.faq {
  .h2 {
    color: var(--clr-default-900);
  }

  &__list {
    margin-top: 2rem;
  }

  &__title {
    position: relative;
    border-radius: 0.8rem;
    padding: 2rem 10rem 2rem 4rem;
    width: 100%;
    box-shadow: 0 0.2rem 0.4rem 0 rgba(171, 190, 209, 0.2);
    font-size: fluid(2rem, 1.8rem);
    line-height: 135%;
    color: var(--clr-default-100);
    background-color: var(--clr-primary-100);
    cursor: pointer;

    @media (max-width: em(576)) {
      padding: 2rem 6rem 2rem 2rem;
    }

    &::before {
      content: "";
      position: absolute;
      right: 5rem;
      top: 50%;
      width: 0.1rem;
      height: 2rem;
      background-color: var(--clr-default-100);
      transform: translateY(-50%);
      transition: height var(--transition);

      @media (max-width: em(576)) {
        right: 3.5rem;
      }
    }

    &::after {
      content: "";
      position: absolute;
      right: 4rem;
      top: 50%;
      width: 2rem;
      height: 0.1rem;
      background-color: var(--clr-default-100);
      transform: translateY(-50%);

      @media (max-width: em(576)) {
        right: 2.5rem;
      }
    }
  }

  &__item {
    background-color: var(--clr-bg-100);

    &-body {
      overflow: hidden;
      max-height: 0;
      box-sizing: content-box;
      line-height: 160%;
      transition: max-height var(--transition);

      p {
        margin-top: 0;
        padding: 2rem 4rem;
        font-weight: 400;
        font-style: normal;
        font-size: fluid(1.8rem, 1.4rem);
        line-height: 155%;
        letter-spacing: 0.036rem;

        @media (max-width: em(576)) {
          padding: 2rem;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 3rem;
      transition: margin var(--transition);

      @media (max-width: em(767)) {
        margin-bottom: 1.5rem;
      }
    }

    &--active {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-bottom: 0rem;

        @media (max-width: em(767)) {
          margin-bottom: 0;
        }
      }

      .faq__title {
        &::before {
          height: 0;
        }
      }
    }
  }
}
